<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title v-if="data.type == 1"> ICS References </v-toolbar-title>
          <v-toolbar-title v-if="data.type == 2"> PAR References </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6">
                  <span v-if="data.type == 1">INVENTORY CUSTODIAN SLIP</span>
                  <span v-if="data.type == 2">PROPERTY ACKNOWLEDGEMENT RECEIPT</span>
                  </v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Cluster:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.FundCode }} - {{ data.SpecificFundSourceDescription }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Supplier:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.CompanyName }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">IAR No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.IARCODE }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PO No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.POCode }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date Created:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(data.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">RIS No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.RISCODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Receiver:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.receiver }}</v-subheader>
            </v-col>
          </v-row>

          <!-- <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">ICS No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ data.ICSCODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">ICS Purpose:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{data.ICSPurpose}}</v-subheader>
            </v-col>
          </v-row> -->
          </div>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels popout class="mt-5" v-model="panel">
                <v-expansion-panel
                  v-for="(item, index) in references"
                  :key="index"
                >
                  <v-expansion-panel-header>
                  <v-row
                    no-gutters
                    style="width: 100%"
                    align="center"
                  >
                  <v-col cols="8">
                  <span v-if="data.type == 1"><strong>{{item.ICSCODE}}</strong> - {{item.ICSPurpose}}</span>
                  <span v-if="data.type == 2"><strong>{{item.PARCODE}}</strong> - {{item.PARPurpose}}</span>
                  </v-col>
                  <v-col cols="2">
                  <span><b>{{item.items.length}}</b> {{item.items.length > 1 ? "items" : "item"}}</span>
                  </v-col>
                  <v-col cols="2" align="end">
                    <span class="text-no-wrap">
                    <v-chip
                    class="white--text mr-3"
                    style="max-width:72px"
                    color="header darken-2"
                    small
                    v-if="item.RenewID == null && item.TransferID == null"
                    >
                    <v-icon left>
                        mdi-numeric-1-box-outline
                    </v-icon>
                    First
                    </v-chip>
                    <v-chip
                    class="white--text mr-3"
                    style="max-width:100px"
                    color="header darken-2"
                    small
                    v-if="item.RenewID"
                    >
                    <v-icon left>
                        mdi-autorenew
                    </v-icon>
                    Renewed
                    </v-chip>
                    <v-chip
                    class="white--text mr-3"
                    style="max-width:100px"
                    color="blue darken-2"
                    small
                    v-if="item.TransferID && item.ReturnID"
                    >
                    <v-icon left>
                        mdi-clipboard-arrow-left
                    </v-icon>
                    Returned
                    </v-chip>
                    <v-chip
                    class="white--text mr-3"
                    style="max-width:110px"
                    color="amber darken-2"
                    small
                    v-if="item.TransferID"
                    >
                    <v-icon left>
                        mdi-transfer
                    </v-icon>
                    Transferred
                    </v-chip>
                    <v-chip
                    class="white--text mr-3"
                    style="max-width:102px"
                    color="error darken-2"
                    small
                    v-if="item.IsCancelled"
                    >
                    <v-icon left>
                        mdi-cancel
                    </v-icon>
                    Cancelled
                    </v-chip>
                    </span>
                  </v-col>
                  </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="headers"
                      :items="item.items"
                      item-key="ICSItemID"
                      class="elevation-1 mt-4"
                      :expanded.sync="expanded1"
                      :show-expand="!item.All"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <span class="green--text text--darken-2"
                            >List Of Items</span
                          >
                          <v-spacer></v-spacer>
                          <span>
                          <v-btn
                            outlined
                            color="red darken-2"
                            dark
                            small
                            @click="print(item)"
                            class="mr-2"
                          >
                            <v-icon>print</v-icon>
                            Print
                          </v-btn>
                          </span>
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.UnitCost`]="{ item }">
                        <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                      </template>
                      <template v-slot:[`item.Amount`]="{ item }">
                        <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <div class="my-5">
                          <v-row v-for="(mr, index) in item.MRData" :key="index">
                            <v-col cols="12" class="px-2">
                              <v-row>
                                <v-col cols="12" sm="3">
                                  <strong>Persons Accountable: </strong>
                                </v-col>
                                <v-col cols="12" sm="9">
                                  {{getPANames(mr.UserID)}}
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" sm="4" class="px-2">
                              <strong>Brand: </strong> {{mr.Brand || 'Not applicable'}}
                            </v-col>
                            <v-col cols="12" sm="4" class="px-2">
                              <strong>Model: </strong> {{mr.Model || 'Not applicable'}}
                            </v-col>
                            <v-col cols="12" sm="4" class="px-2">
                              <strong>Serial No.: </strong> {{mr.SerialNo || 'Not applicable'}}
                            </v-col>
                            <v-col v-if="item.MRData.length > index + 1 "><v-divider></v-divider></v-col>
                          </v-row>
                          </div>
                        </td>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "QTY", align:"center", value: "Qty", sortable: false },
      { text: "Amount", align:"end", value: "Amount", sortable: false },
    ],
    expanded1: [],
    itemList: [],
    switchLink: false,
    references: [],
    panel: null,
  }),
  watch: {
    data: {
      handler(data) {
        if (data.ICSID || data.PARID) {
          if(data.type == 1){
            this.getReferences(data.ICSID)
          }
          else if(data.type == 2){
            this.getReferences(data.PARID)
          }
          this.dialog = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDispenseTypes();
  },
  methods: {
    print(item) {
      if(item.ICSID){
        window.open(this.apiUrl + "/ics/report/" + item.ICSID);
      }
      else if(item.PARID){
        window.open(this.apiUrl + "/par/report/" + item.PARID);
      }
    },
    closeDialog() {
      this.itemList = [];
      this.dialog = false;
      this.eventHub.$emit("closeReference", false);
    },
    getReferences(id) {
      let data = new FormData();
      let route = '';
      if(this.data.type == 1){
        data.append("ICSID", id);
        route = "/ics/getReferences";
      }
      else if(this.data.type == 2){
        data.append("PARID", id);
        route = "/par/getReferences";
      }
      
      this.axiosCall(route, "POST", data).then((res) => {
        this.references = res.data.data;
        this.references.forEach((item, index) => {
          if(this.data.type == 1 && item.ICSID == this.data.ICSID){
            this.panel = index;
          }
          else if(this.data.type == 2 && item.PARID == this.data.PARID){
            this.panel = index;
          }
        });
      });
    },
    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      data.append("ICSID", this.data.ICSID);
      this.axiosCall("/ics/getItems", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        // this.getMRData();
        this.users = res.data.data.users;
      });
    },
    getMRData(){
      this.itemList.forEach(item => {
        let data = new FormData();
        data.append("ICSItemID", item.ICSItemID);
        this.axiosCall("/mritem/ics/getItemsForView", "POST", data).then((res) => {
          if(res.status){
            if(res.data.data.length > 0){
              res.data.data.forEach(item2 => {
                item2.UserID = item2.UserID.split(",").map(Number)
                if(this.ICSDispenseType == 1){
                item2.UserID = item2.UserID[0]
                }
              });
              item.MRData = res.data.data;
            }
            else{
              let MRData = []
            for (let index = 0; index < item.Qty; index++) {
              MRData.push({
                Brand: null,
                Model: null,
                SerialNo: null,
                UserID: null
              });
            }
            item.MRData = MRData
          }
          }
      });
      });
    },
    getPANames(data){
      if(data){
      let result = [];
      if(Array.isArray(data)){
        data.forEach(item1 => {
          this.users.forEach(item2 => {
            if(item1 == item2.UserID){
              result.push(item2.name)
            }
        });
        });
      }
      else{
        this.users.forEach(item => {
          if(data == item.UserID){
            result.push(item.name)
          }
        });
      }
      return result.join(', ')
      }
      else{
        return ''
      }
      
    },
    getDispenseTypes() {
      this.axiosCall("/getAllDispenseType", "GET").then((res) => {
        this.dispenseTypes = res.data.data;
      });
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>